import Axios from "axios"
import React, { useState, useEffect } from "react"
import baseUrl from "../components/baseUrl"
import Layout from "../components/layout"
import OverviewCards from "../components/overviewCards"
import "../styles/global.css"
import Login from "./login"

function App() {
  const [isLoggedIn, setIsLoggedIn] = useState(false)
  const [totalCount, settotalCount] = useState(0)
  const [yogaCount, setyogaCount] = useState(0)
  const [oneCount, setoneCount] = useState(0)
  useEffect(() => {
    if (
      window.sessionStorage.getItem("user") ||
      window.localStorage.getItem("user")
    ) {
      setIsLoggedIn(true)

      //fetching participant count in each category
      Axios.get(`${baseUrl}/overviewcount`)
        .then(jsonres => {settotalCount(jsonres.data.total)
          setyogaCount(jsonres.data.yoga)
          setoneCount(jsonres.data.one)
        })
        .catch(err => {
          alert("Error fetching data")
          
        })

     
    }
  }, [isLoggedIn])

  let route
  //If logged in, the dashboard will be shown, otherwise, the login page
  if (isLoggedIn) {
    route = (
      <Layout route="/">
        <div className="overview ">
          <h2 className="greeting p-6">OVERVIEW</h2>

          <OverviewCards stats={totalCount} text={"TOTAL PARTICIPANTS"} />

          <OverviewCards stats={yogaCount} text={"YOGA CIRCLE PARTICIPANTS"} />
          <OverviewCards stats={oneCount} text={"ONE TO ONE PARTICIPANTS"} />
        </div>
      </Layout>
    )
  } else {
    route = <Login setIsLoggedIn={setIsLoggedIn} />
  }
  return route
}

export default App
